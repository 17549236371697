/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gorditas&display=swap');

/*globals*/
.body{
  background-color: #eff3f7;
}

*{
  font-family: 'Montserrat', sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Signika', sans-serif;
  font-weight: 500;
  
}



/* Nav */
.nav-link{
  font-size: 13px;
  color: #000;
  margin-left: 10px;
  font-weight: 500;
  margin-right: 10px;
  padding: 0;
  padding-bottom: 5px;
}

.nav-link:hover{
  color: #0f678f;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color: #0f678f;
  font-weight: 600;
  padding-right: 0;
  padding-left: 0;
  font-size: 14px;

}

.navbar-brand > span{
  font-family: 'Signika', sans-serif;
  font-weight: 600;
  font-size: 30px;
}

.sticky-top{
  background-color: rgb(255, 255, 255, 0.9);
}

.nav-tabs .nav-link{
  margin: 0;
  padding: 5px 12px;
  
}

.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link:hover {
  border-bottom: none;
  background-color: #0f678f;
  color: #fff;
}

.navbar-toggler{
  border: none;
}

.navbar-toggler:focus{
  box-shadow: none;
}
.navbar{
  padding: 0;
}

.navbar-toggler-icon{
  border: none;
  
}

.navbar-toggler>.close {
  display:inline;
}
.navbar-toggler.collapsed>.close, .navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
  display:inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url('./images/close-icon.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;

}

.navbar-nav .dropdown-menu{
  border: none;
  font-size: 13px;
  padding: 0;
  border-radius: 10px;
}
.dropdown-item:hover{
background-color:#0f678f;
color: #fff;
font-weight: 900;
}

.dropdown-item.active, .dropdown-item:active{
  background-color:#0f678f;
  color: #fff;
  font-weight: 900;
}
/*Hero */

.hero-card-overlay{
  padding-top: 12vh;
  background: rgba(0, 0, 0, 0.4)
}


.hero-tab{
  background-color: #fff;
  border-radius: 10px;
  margin-top: -60px;
  position: relative;
  overflow: hidden;
}


.hero-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/emirates.jpg');
  background-repeat: repeat-y;
  --bs-card-border-width: 0;

}

.hero-title{
  font-family: 'Eczar', serif;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}

.hero-image{
  position: relative;
}

.tab-content{
  font-size: 11px;
  font-weight: 600;
}

.tab-content, .col-md-3{
  padding: 5px;
}




/* Utils */
.fa-solid, .fa-regular{
  color: #494949;
  background-color: #dfeaf5;
  padding: 10px;
  border-radius: 20px;
}

.fa-service{
  color: #0f678f;
  background-color: #fff;
}


.white-section{
  background-color: #fff;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.catch{
  background-color: #95b2da;
  color: #fff;
}


.step-list{
  margin:0;
  padding:0;
  list-style:none; 
}

.step-list > li {
  background: url('./images/arrow.png') no-repeat left center;
  padding: 5px 0 5px 45px;
  margin: 0;
  font-size: 16px;
}

.metric{
  font-size: 70px;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  line-height: 1;
}

.metric-sub{
  font-weight: bold;
  font-size: 10px;
  font-weight: 600; 
  line-height:0;
}

.designation{
  font-size: 10px;
  line-height: 1;
}

.client-name{
  line-height: 1;
  padding: 0;
  margin: 0;
}

.fas{
  font-size: 12px;
}

.fa-fas{
  font-size: 20px;
}

.whatsapp{
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.fa-whatsapp2 {
  background-color: transparent !important;
  color: rgb(31, 207, 31) !important;
  border-radius: 5px;
}


.chat{
  color: rgb(31, 207, 31);
  font-size: 11px;
  border: 1px solid rgb(31, 207, 31);
  padding: 0 2px;
  border-radius: 2px;
  font-family: 'Signika', sans-serif;
  
}

/* button */
.btn-primary{
  background-color: #0f678f;
  padding: 5px 30px;
  border-radius: 50px;
  font-size: 12px;
  border: none;
  }
  
  .btn-primary:hover{
    background-color: white;
    padding: 5px 30px;
    border-radius: 50px;
    font-weight: 600;
    color: #0f678f;
    border: 1px solid #0f678f;
    }
.client{
  background-color: #0f678f;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 300;
  margin-top: 40px;
  border: none;
    border: 1px solid #0f678f;
    
}
  .send{
    background-color: #0f678f;
    padding: 10px 50px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    border: none;
  }

  .btn-email{
    border-radius: 5px;
    background-color: #0f678f;
    opacity: 0.8;
  }

  .more-button{
    padding: 2px 30px;
    border-radius: 5px;
  }

  .more-button:hover{
    color: #0f678f;
    background-color: #fff;
    padding: 2px 30px;
    border-radius: 5px;
  }
  
  .btn-secondary{
  color: #cb6869;
  border-color: #cb6869;
  padding: 5px 30px;
  border-radius: 50px;
  font-weight: 600;
  }
  
  .btn-secondary:hover{
    color: #fff;
    background-color: #0f678f;
    padding: 10px 50px;
    border-radius: 50px;
    font-weight: 600;
  }

/* card */

.why-card{
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 15px;
}

.ceo-card{
  border: none;
  border-radius: 20px;
  box-shadow: 1px 1px 5px 5px #eff3f7;
  padding: 10px;
}

.rating-card{
  border: none;
  border-radius: 10px;
  margin: 10px;
  color: #000;
}


/* footer */
.footer{
  background-color: #253b46;
  color: #F3F4F8;
  font-weight: 50;
}


.copy{
  background-color: #253b46;
  color: #F3F4F8;
  padding: 0;
  font-size: 11px;
  font-style: italic;
}
.footer > ul{
  list-style-type: none;
  margin-right: 25px;
  padding-left: 0;
}
.footer-link{
  padding-top: 5px;
  padding-bottom: 10px;
}
.footer-list{
  font-size: 15px;
  padding-top: 2px;
  padding-bottom: 10px;
}

.footer-logo{
  padding-left: 0;
  padding-top: 20px;

}

.fa-brands{
  background-color: #fff;
  color: #0f678f;

}
.fa-whatsapp{
  color: rgb(22, 223, 22);
}

.fa-instagram{
  background: linear-gradient(to bottom, #8a3ab9, #e95950, #bc2a8d, #fccc63);
  color: #fff;
  border-radius: 4px;

}
ul > a{
  text-decoration: none;
  color: #F3F4F8;
}

.footer-link:hover{
  cursor: pointer;
}


/* about page */
.about-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/about.jpg');
  --bs-card-border-width: 0;

}

.about-card-overlay{
  padding-top: 16vh;
  background: rgb(0, 0, 0, 0.3);
  border: none;
}

.about-details{
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.about-text{
  text-align: justify;
}

.customer{
  background: #eff3f7;
}

.customers{
  background-color: #95b2da;
  color: #fff;
  padding: 20px;
}

.about-list{
  padding-left: 28px;
  padding-right: 28px;
  text-align:justify;

}

.core-value{
  background-color: #fff;
  padding: 20px;
  border-radius: 0 0 20px 20px;
}

.value-card{
border: 1px solid #adc9d6;
border-radius: 5px;
margin: 10px;
text-align: justify;
}

.ceo-about-card{
  background-color: transparent;
  border: none;
  box-shadow: 1px 1px 5px 5px #e5eaf0;
  border-radius: 20px;
}

/* services pages */
.concierge-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/jet-hire.jpg');
  --bs-card-border-width: 0;

}

.about-card-overlay{
  padding-top: 16vh;
  background: rgb(0, 0, 0, 0.6);
  border: none;
}

.service-card{
  background-color: #fff;
  padding: 20px;
  border : none;
}

.service-card-title{
  font-family: 'Signika', sans-serif;
}

.service-header{
  margin-top: -42px;
}

.document-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/passport-wide.jpg');
  --bs-card-border-width: 0;

}

.support-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/support.jpg');
  --bs-card-border-width: 0;

}

.hotel-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/hotel-page.jpg');
  --bs-card-border-width: 0;

}

.tour-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/tour.jpg');
  --bs-card-border-width: 0;

}

/*contact page */
.contact-form{
  border: none;
}

.assurance{
  color: #0f678f;
}

.form-control{
  border: none;
  box-shadow: 0 0 5px #adc9d6;
  font-size: 14px;
}

.form-control:focus{
  box-shadow: 0 0 5px #0f678f;
}

.contact-card{
  background-color: #fff;
  border-radius: 10px;
  margin-top: -80px;
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.call-email-card{
  background-color: #fff;
  padding: 20px;
  border : none;
  box-shadow: 0 0 5px #adc9d6;
  
}

.call-email-holder{
margin-top: -150px;
}


.fa-call{
  background-color: #fff;
  border: none;
  color: #0f678f;
  font-size: 30px !important;
}

.call-text{
  font-size: 13px;
  font-weight: 400;
}

.call-link{
  color: #0f678f;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}

.call-link:hover{
  border-radius: 5px;
  color: #fff;
  background-color: #0f678f;
}
.map{
  border: none;
}

.contact-new-card{
  border: none;
  overflow: scroll;
}

.contact-message{
  color: #000;
}

.contact-page-card{
  min-height: 400px;
  overflow-x: hidden;
  background-image: url('./images/contact.jpg');
  --bs-card-border-width: 0;
}

.contact-card-overlay{
  padding-top: 16vh;
  background: rgb(0, 0, 0, 0.8);
  border: none;
}

/* media queries */
@media only screen and (min-width: 768px) {
  .tab-content{
    font-size:11px;
  }
  .tab-content, .col-md-3{
    padding: 5px;
  }

  .fa-solid, .fa-regular{
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
    
  }

  .hero-card-overlay{
    padding-top: 10vh;
  }
  .hero-title{
    font-size: 60px;
}
}


@media only screen and (min-width: 992px){
  .nav-link{
    padding-bottom: 0;
  }
  .navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #0f678f;
    font-weight: 600;
    border-bottom: 1px solid #0f678f;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    font-size: 14px;
  
  }
  .hero-card-overlay{
    padding-top: 18vh;
  }
}

@media only screen and (max-width: 992px){
.sections{
  padding-top: 20px;
}
.pages{
  padding-top: 10px;
  padding-bottom: 50px;
}
.nav-link{
  padding-bottom: 0;
  padding-bottom: 10px;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
  color: #0f678f;
  font-weight: 300;
  font-size: 16px;
  border: none;

}
.navbar-nav .dropdown-menu{
  padding: 10px;
}

.document-card{
  background-image: url('./images/passport-small.jpg');

}
.support-card{
  background-image: url('./images/support-small.jpg');

}

.concierge-card{
  background-image: url('./images/jet-hire-small.jpg');

}

.hotel-card{
  background-image: url('./images/hotel-page-small.jpg');

}

.tour-card{
  background-image: url('./images/tour-small.jpg');

}
   
.contact-page-card{
  background-image: url('./images/contact-small.jpg');
 
}
}
@media only screen and (max-width: 575px){
.second-call-email-card-holder{
  margin-top: 0px;
}
}
